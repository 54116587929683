import * as React from 'react'
import Layout from '../../components/Layout'

import {Helmet} from "react-helmet"
import {Link} from 'gatsby'

//Modular stylesheet

//Global stylesheet
import "../../components/styles/global.css"

const Index = () => {
    return(
        <Layout pageTitle={"ayayaqi"}>
            <Helmet>
                <meta charSet="UTF-8"/>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>
                <meta name="robots" content="noindex"/>

                <title>projects | ayayaqi</title>

                <meta name="author" content="ayayaqi"/>
                <meta name="description" content="cool content and stuff."/>

                <meta property="og:title" content="ayayaqi"/>
                <meta property="og:description" content="cool content and stuff."/>
                <meta property="og:site_name" content="ayayaqi.moe/"/>

                <link rel="preconnect" href="https://fonts.googleapis.com"/>
                <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin/>
                <link href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
                      rel="stylesheet"/>

            </Helmet>

            <div>

                <div class="mainWrapper">

                    <h1>
                        the projects page
                    </h1>
                    <p>
                        eventually cool things will go here, i hope. but not right now.
                    </p>


                </div>
            </div>
        </Layout>
    )
}

export default Index